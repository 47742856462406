import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { DiscountForm } from "../DiscountForm";
import * as S from "./styles";

/**
 * Payment options used in checkout.
 */
var CheckoutPayment = function CheckoutPayment(_ref) {
  var promoCodeErrors = _ref.promoCodeErrors,
      promoCodeDiscountFormId = _ref.promoCodeDiscountFormId,
      promoCodeDiscountFormRef = _ref.promoCodeDiscountFormRef,
      promoCodeDiscount = _ref.promoCodeDiscount,
      addPromoCode = _ref.addPromoCode,
      removeVoucherCode = _ref.removeVoucherCode,
      submitUnchangedDiscount = _ref.submitUnchangedDiscount;

  var _useState = useState(!!(promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode)),
      _useState2 = _slicedToArray(_useState, 2),
      showPromoCodeForm = _useState2[0],
      setShowPromoCodeForm = _useState2[1];

  useEffect(function () {
    var isVoucherCode = !!(promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode);

    if (isVoucherCode) {
      setShowPromoCodeForm(isVoucherCode);
    }
  }, [promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode]);

  var handleChangeShowPromoCodeForm = function handleChangeShowPromoCodeForm() {
    setShowPromoCodeForm(!showPromoCodeForm);
  };

  var handleSubmitPromoCode = function handleSubmitPromoCode(discountForm) {
    var newPromoCode = discountForm === null || discountForm === void 0 ? void 0 : discountForm.promoCode;
    var savedPromoCode = promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode;

    if ((!newPromoCode || !showPromoCodeForm) && savedPromoCode) {
      removeVoucherCode(savedPromoCode);
    } else if (newPromoCode && newPromoCode !== savedPromoCode) {
      addPromoCode(newPromoCode);
    } else {
      submitUnchangedDiscount();
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.paymentMethod)), React.createElement(Checkbox, {
    "data-test": "checkoutPaymentPromoCodeCheckbox",
    name: "payment-promo-code",
    checked: showPromoCodeForm,
    onChange: handleChangeShowPromoCodeForm
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutPayment.CheckoutPayment.1261952539",
    defaultMessage: "Haben Sie einen Gutscheincode? Geben Sie das Wort PRONATURA ein, wenn Sie Mitglied von Pro Natura sind oder werden m\xF6chten."
  })), showPromoCodeForm && React.createElement(S.DiscountField, null, React.createElement(DiscountForm, {
    discount: {
      promoCode: promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode
    },
    formId: promoCodeDiscountFormId,
    formRef: promoCodeDiscountFormRef,
    handleSubmit: handleSubmitPromoCode,
    errors: promoCodeErrors
  })), React.createElement(S.Divider, null)));
};

export { CheckoutPayment };