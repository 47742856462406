import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { useAuth, useCart, useCheckout } from "@saleor/sdk";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, CartFooter, CartHeader } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartRow } from "@components/organisms";
import { Cart, CartEmpty } from "@components/templates";
import { BASE_URL } from "@temp/core/config";
import { checkoutMessages } from "@temp/intl";
var title = React.createElement("h1", {
  "data-test": "cartPageTitle"
}, React.createElement(FormattedMessage, {
  id: "@next.pages.CartPage.CartPage.1654238178",
  defaultMessage: "Mein Warenkorb"
}));

var getShoppingButton = function getShoppingButton(history) {
  return React.createElement(Button, {
    testingContext: "cartPageContinueShoppingButton",
    onClick: function onClick() {
      return history.push(BASE_URL);
    }
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping));
};

var getCheckoutButton = function getCheckoutButton(history, user) {
  return React.createElement(Button, {
    testingContext: "proceedToCheckoutButton",
    onClick: function onClick() {
      return history.push(user ? "/checkout/" : "/login/");
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.pages.CartPage.CartPage.2706566253",
    defaultMessage: "ZUR KASSE GEHEN"
  }));
};

var cartHeader = React.createElement(CartHeader, null);

var prepareCartFooter = function prepareCartFooter(totalPrice, shippingTaxedPrice, promoTaxedPrice, subtotalPrice) {
  return React.createElement(CartFooter, {
    subtotalPrice: React.createElement(TaxedMoney, {
      "data-test": "subtotalPrice",
      taxedMoney: subtotalPrice
    }),
    totalPrice: React.createElement(TaxedMoney, {
      "data-test": "totalPrice",
      taxedMoney: totalPrice
    }),
    shippingPrice: shippingTaxedPrice && shippingTaxedPrice.gross.amount !== 0 && React.createElement(TaxedMoney, {
      "data-test": "shippingPrice",
      taxedMoney: shippingTaxedPrice
    }),
    discountPrice: promoTaxedPrice && promoTaxedPrice.gross.amount !== 0 && React.createElement(TaxedMoney, {
      "data-test": "discountPrice",
      taxedMoney: promoTaxedPrice
    })
  });
};

var generateCart = function generateCart(items, removeItem, updateItem) {
  return items === null || items === void 0 ? void 0 : items.map(function (_ref, index) {
    var _variant$product, _variant$product2, _variant$product3, _variant$product4, _variant$product4$thu, _variant$pricing, _variant$attributes;

    var id = _ref.id,
        variant = _ref.variant,
        quantity = _ref.quantity,
        totalPrice = _ref.totalPrice;
    return React.createElement(CartRow, {
      key: id ? "id-".concat(id) : "idx-".concat(index),
      index: index,
      id: (variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.id) || "",
      name: (variant === null || variant === void 0 ? void 0 : (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.name) || "",
      maxQuantity: variant.quantityAvailable || quantity,
      quantity: quantity,
      onRemove: function onRemove() {
        return removeItem(variant.id);
      },
      onQuantityChange: function onQuantityChange(quantity) {
        return updateItem(variant.id, quantity);
      },
      thumbnail: _objectSpread({}, variant === null || variant === void 0 ? void 0 : (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : _variant$product3.thumbnail, {
        alt: (variant === null || variant === void 0 ? void 0 : (_variant$product4 = variant.product) === null || _variant$product4 === void 0 ? void 0 : (_variant$product4$thu = _variant$product4.thumbnail) === null || _variant$product4$thu === void 0 ? void 0 : _variant$product4$thu.alt) || ""
      }),
      totalPrice: React.createElement(TaxedMoney, {
        taxedMoney: totalPrice
      }),
      unitPrice: React.createElement(TaxedMoney, {
        taxedMoney: variant === null || variant === void 0 ? void 0 : (_variant$pricing = variant.pricing) === null || _variant$pricing === void 0 ? void 0 : _variant$pricing.price
      }),
      sku: variant.sku,
      attributes: (_variant$attributes = variant.attributes) === null || _variant$attributes === void 0 ? void 0 : _variant$attributes.map(function (attribute) {
        return {
          attribute: {
            id: attribute.attribute.id,
            name: attribute.attribute.name || ""
          },
          values: attribute.values.map(function (value) {
            return {
              id: value === null || value === void 0 ? void 0 : value.id,
              name: (value === null || value === void 0 ? void 0 : value.name) || "",
              value: value === null || value === void 0 ? void 0 : value.value
            };
          })
        };
      })
    });
  });
};

export var CartPage = function CartPage(_ref2) {
  var _checkout$shippingMet;

  _objectDestructuringEmpty(_ref2);

  var history = useHistory();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _useCart = useCart(),
      loaded = _useCart.loaded,
      removeItem = _useCart.removeItem,
      updateItem = _useCart.updateItem,
      items = _useCart.items,
      totalPrice = _useCart.totalPrice,
      subtotalPrice = _useCart.subtotalPrice,
      shippingPrice = _useCart.shippingPrice,
      discount = _useCart.discount;

  var shippingTaxedPrice = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingMet = checkout.shippingMethod) === null || _checkout$shippingMet === void 0 ? void 0 : _checkout$shippingMet.id) && shippingPrice ? {
    gross: shippingPrice,
    net: shippingPrice
  } : null;
  var promoTaxedPrice = discount && {
    gross: discount,
    net: discount
  };

  if (loaded && (items === null || items === void 0 ? void 0 : items.length)) {
    return React.createElement(Cart, {
      title: title,
      button: getCheckoutButton(history, user),
      cartHeader: cartHeader,
      cartFooter: prepareCartFooter(totalPrice, shippingTaxedPrice, promoTaxedPrice, subtotalPrice),
      cart: items && generateCart(items, removeItem, updateItem)
    });
  }

  return React.createElement(CartEmpty, {
    button: getShoppingButton(history)
  });
};