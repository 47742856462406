import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  padding: 5px;\n\n  path {\n    transition: 300ms;\n  }\n\n  &:hover,\n  &:focus {\n    path {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  color: #7d7d7d;\n  padding: 0;\n\n  &:hover {\n    background-color: transparent;\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin-left: auto;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  line-height: 1;\n  margin-right: ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  line-height: 0;\n\n  svg {\n    width: 30px;\n    height: 30px;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  margin: 0;\n  padding: 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: ", ";\n  padding: 0 15px;\n  border-bottom: 1px solid ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  max-width: calc(100vw - 5rem);\n  width: 30rem;\n  overflow: hidden;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  outline: none;\n  padding: 0 25px 0px 15px;\n  text-transform: none;\n  transition: 300ms;\n  height: ", ";\n  width: 100%;\n  ", "\n\n  path {\n    transition: 300ms;\n  }\n\n  &:hover,\n  &:focus {\n    ", "\n\n    path {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { Link as _Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { css } from "styled-components";
import { NavLink as _NavLink } from "@components/atoms";
import { NAVBAR_HEIGHT } from "../TopNavbar/styles";
var MenuItemStyles = css(_templateObject(), NAVBAR_HEIGHT, function (_ref) {
  var theme = _ref.theme;
  return "\n    border-bottom: 1px solid ".concat(theme.colors.divider, ";\n    font-weight: ").concat(theme.typography.boldFontWeight, ";\n    font-size: ").concat(theme.typography.baseFontSize, ";\n  ");
}, function (_ref2) {
  var theme = _ref2.theme;
  return "\n      color: ".concat(theme.colors.primary, ";\n      background-color: ").concat(theme.colors.hoverLightBackground, ";\n    ");
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.primary;
});
export var Wrapper = styled.div(_templateObject2());
export var Bar = styled.div(_templateObject3(), NAVBAR_HEIGHT, function (props) {
  return props.theme.colors.divider;
});
export var Menu = styled.ul(_templateObject4(), function (props) {
  return props.theme.colors.white;
});
export var Item = styled.li(_templateObject5());
export var NavButton = styled.button(_templateObject6(), MenuItemStyles);
export var NavLink = styled(_NavLink).attrs({
  fullWidth: true
})(_templateObject7(), MenuItemStyles);
export var Link = styled(_Link)(_templateObject8(), MenuItemStyles);
export var LogoWrapper = styled(ReactSVG)(_templateObject9());
export var IconWrapper = styled.span(_templateObject10(), function (_ref4) {
  var theme = _ref4.theme;
  return theme.spacing.spacer;
});
export var SubcategoryIcon = styled.div(_templateObject11());
export var BackButton = styled(NavButton)(_templateObject12());
export var CloseIconWrapper = styled.button(_templateObject13(), function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.primary;
});