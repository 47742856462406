import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAccountUpdate, useAuth } from "@saleor/sdk";
import { Attribute, IconButton, Tile } from "@components/atoms";
import { AccountUpdateForm } from "./AccountUpdateForm";
import * as S from "./styles";
export var AccountTile = function AccountTile() {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isEditing = _React$useState2[0],
      setIsEditing = _React$useState2[1];

  var _useAccountUpdate = useAccountUpdate(),
      _useAccountUpdate2 = _slicedToArray(_useAccountUpdate, 2),
      setAccountUpdate = _useAccountUpdate2[0],
      _useAccountUpdate2$ = _useAccountUpdate2[1],
      data = _useAccountUpdate2$.data,
      error = _useAccountUpdate2$.error;

  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  React.useEffect(function () {
    if (data && !error) {
      setIsEditing(false);
    }
  }, [data, error]);
  return React.createElement(S.TileWrapper, null, React.createElement(Tile, null, React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.AccountTile.2761912922",
    defaultMessage: "MEINE DATEN"
  })), React.createElement(S.Content, null, React.createElement(S.HeaderSmall, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.AccountTile.1306229551",
    defaultMessage: "Pers\xF6nliche Angaben"
  }), !isEditing && React.createElement(IconButton, {
    testingContext: "editDetailsButton",
    name: "edit",
    size: 22,
    onClick: function onClick() {
      return setIsEditing(function (isEditing) {
        return !isEditing;
      });
    }
  })), isEditing ? React.createElement(AccountUpdateForm, {
    initialValues: {
      firstName: user && user.firstName || "",
      lastName: user && user.lastName || ""
    },
    handleSubmit: function handleSubmit(data) {
      setAccountUpdate({
        input: data
      });
    },
    hide: function hide() {
      setIsEditing(false);
    }
  }) : React.createElement(S.ContentOneLine, {
    "data-test": "personalDetailsSection"
  }, React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.firstName),
    attributeValue: user && user.firstName || "-",
    testingContext: "firstNameText"
  }), React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.lastName),
    attributeValue: user && user.lastName || "-",
    testingContext: "lastNameText"
  }))))));
};