import _defineProperty from "@babel/runtime/helpers/defineProperty";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Address, DropdownMenu, IconButton, Tile } from "@components/atoms";
import * as S from "./styles";
var defaultShippingAddress = React.createElement(S.MenuItem, null, React.createElement(FormattedMessage, {
  id: "@next.components.molecules.AddressTile.AddressTile.2359544408",
  defaultMessage: "Setze als Standard-Lieferadresse"
}));
var defaultBillingAddress = React.createElement(S.MenuItem, null, React.createElement(FormattedMessage, {
  id: "@next.components.molecules.AddressTile.AddressTile.1408376640",
  defaultMessage: "Setze als Standard-Rechnungsadresse"
}));
export var AddressTile = function AddressTile(_ref) {
  var onEdit = _ref.onEdit,
      onRemove = _ref.onRemove,
      setDefault = _ref.setDefault,
      address = _ref.address;
  var intl = useIntl();
  var header = React.createElement(S.HeaderContent, null, React.createElement(DropdownMenu, {
    type: "clickable",
    header: React.createElement(IconButton, {
      testingContext: "expandButton",
      name: "expand",
      size: 24
    }),
    items: [{
      content: defaultBillingAddress,
      onClick: function onClick() {
        setDefault("BILLING");
      },
      testingContext: "set-billing"
    }, {
      content: defaultShippingAddress,
      onClick: function onClick() {
        setDefault("SHIPPING");
      },
      testingContext: "set-shipping"
    }]
  }), address.isDefaultBillingAddress && address.isDefaultShippingAddress ? intl.formatMessage(_defineProperty({
    defaultMessage: "Default Address",
    "id": "@next.components.molecules.AddressTile.AddressTile.1224809208"
  }, "id", "@next.components.molecules.AddressTile.AddressTile.1224809208")) : address.isDefaultShippingAddress ? intl.formatMessage(_defineProperty({
    defaultMessage: "Default Shipping Address",
    "id": "@next.components.molecules.AddressTile.AddressTile.4109348993"
  }, "id", "@next.components.molecules.AddressTile.AddressTile.4109348993")) : address.isDefaultBillingAddress ? intl.formatMessage(_defineProperty({
    defaultMessage: "Default Billing Address",
    "id": "@next.components.molecules.AddressTile.AddressTile.1578192486"
  }, "id", "@next.components.molecules.AddressTile.AddressTile.1578192486")) : null);
  var footer = React.createElement(S.FooterContent, null, React.createElement("div", null, React.createElement(IconButton, {
    testingContext: "editButton",
    name: "edit",
    onClick: onEdit,
    size: 22
  })), React.createElement("div", null, React.createElement(IconButton, {
    testingContext: "removeButton",
    name: "trash",
    onClick: onRemove,
    size: 19
  })));
  var content = React.createElement(Address, address);
  return React.createElement(S.Wrapper, {
    "data-test-billing-default": address.isDefaultBillingAddress,
    "data-test-shipping-default": address.isDefaultShippingAddress
  }, React.createElement(Tile, {
    footer: footer,
    header: header
  }, content));
};