import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@components/atoms";
import { useHandlerWhenClickedOutside } from "@hooks";
import { commonMessages } from "@temp/intl";
import { Link } from "react-router-dom";
import * as S from "./styles";
export var AccountMenuMobile = function AccountMenuMobile(_ref) {
  var links = _ref.links,
      active = _ref.active;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showMenu = _React$useState2[0],
      setShowMenu = _React$useState2[1];

  var intl = useIntl();

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    setShowMenu(false);
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  var linkToMenuItem = function linkToMenuItem(link) {
    link = link.replace(/\//g, "").replace("-", " ").split(" ").map(function (s) {
      return s.charAt(0).toUpperCase() + s.substring(1);
    }).join(" ");
    var menuItem = link;
    /* eslint-disable default-case */

    switch (link) {
      case "Account":
        menuItem = intl.formatMessage(commonMessages.account);
        break;

      case "Order History":
        menuItem = intl.formatMessage(commonMessages.orderHistory);
        break;

      case "Address Book":
        menuItem = intl.formatMessage(commonMessages.addressBook);
        break;
    }

    return menuItem;
  };

  return React.createElement(S.Wrapper, {
    onClick: function onClick() {
      setShowMenu(true);
    },
    ref: setElementRef()
  }, linkToMenuItem(active), React.createElement(Icon, {
    name: "select_arrow",
    size: 8
  }), showMenu && React.createElement(S.Overlay, null, React.createElement(S.MenuHeader, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountMenuMobile.AccountMenuMobile.2937784323",
    defaultMessage: "Gehe zu"
  })), links.map(function (link) {
    var menuItem = linkToMenuItem(link);
    return React.createElement("div", {
      onClick: function onClick(evt) {
        evt.stopPropagation();
        setShowMenu(false);
      },
      key: link
    }, React.createElement(Link, {
      to: link
    }, React.createElement(S.MenuItem, {
      active: active === link
    }, menuItem, React.createElement(Icon, {
      name: "select_arrow",
      size: 8
    }))));
  })));
};