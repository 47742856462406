import * as React from "react";
import { FormattedMessage } from "react-intl";
export var NothingFound = function NothingFound(_ref) {
  var search = _ref.search;
  return React.createElement("div", {
    className: "search__products--not-found"
  }, React.createElement("p", {
    className: "u-lead u-lead--bold u-uppercase"
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.4125968515",
    defaultMessage: "Tut uns leid, aber wir konnten keine Suchergebnisse finden f\xFCr: {search}",
    values: {
      search: search
    }
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.1491793302",
    defaultMessage: "Geben Sie nicht auf - \xFCberpr\xFCfen Sie die Rechtschreibung, denken Sie an etwas weniger Spezifisches und verwenden Sie dann die Suchleiste oben."
  })));
};
export default NothingFound;