import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { useAuth } from "@saleor/sdk";
import { ServiceWorkerContext } from "@components/containers";

var Notifications = function Notifications() {
  var alert = useAlert();
  var intl = useIntl();

  var _React$useContext = React.useContext(ServiceWorkerContext),
      updateAvailable = _React$useContext.updateAvailable;

  React.useEffect(function () {
    if (updateAvailable) {
      alert.show({
        actionText: intl.formatMessage({
          defaultMessage: "Refresh",
          "id": "app.Notifications.1308518649"
        }),
        content: intl.formatMessage({
          defaultMessage: "Um die Anwendung auf die neueste Version zu aktualisieren, aktualisieren Sie bitte die Seite!",
          "id": "app.Notifications.708165618"
        }),
        title: intl.formatMessage({
          defaultMessage: "Eine Neue Version ist verfügbar!",
          "id": "app.Notifications.2618198553"
        })
      }, {
        onClose: function onClose() {
          location.reload();
        },
        timeout: 0,
        type: "success"
      });
    }
  }, [updateAvailable]);

  var _useAuth = useAuth(),
      authenticated = _useAuth.authenticated;

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      prevAuthenticated = _React$useState2[0],
      setPrevAuthenticated = _React$useState2[1];

  React.useEffect(function () {
    if (prevAuthenticated !== undefined && authenticated !== undefined) {
      if (!prevAuthenticated && authenticated) {
        alert.show({
          title: intl.formatMessage({
            defaultMessage: "Sie sind jetzt angemeldet.",
            "id": "app.Notifications.847885439"
          })
        }, {
          type: "success"
        });
      } else if (prevAuthenticated && !authenticated) {
        alert.show({
          title: intl.formatMessage({
            defaultMessage: "Sie sind jetzt abgemeldet.",
            "id": "app.Notifications.1576198487"
          })
        }, {
          type: "success"
        });
      }

      setPrevAuthenticated(authenticated);
    } else if (authenticated !== undefined) {
      setPrevAuthenticated(authenticated);
    }
  }, [authenticated]);
  return null;
};

export default Notifications;