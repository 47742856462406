import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../core/config";
import Button from "../Button";

var NotFound = function NotFound() {
  return React.createElement("div", {
    className: "not-found-page"
  }, React.createElement("h2", {
    className: "not-found-page__header"
  }, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.3478767873",
    defaultMessage: "404"
  })), React.createElement("div", {
    className: "not-found-page__ruler"
  }), React.createElement("div", {
    className: "not-found-page__message"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.1933457408",
    defaultMessage: "Wir k\xF6nnen die von Ihnen gesuchte Seite nicht finden!"
  }), " "), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.485619467",
    defaultMessage: "M\xF6glicherweise haben Sie sich bei der Adresse vertippt oder die Seite wurde verschoben."
  }), " "), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.1681358216",
    defaultMessage: "Wir entschuldigen uns f\xFCr den Fehler und hoffen, dass Sie trotzdem einen sch\xF6nen Tag haben."
  }))), React.createElement("div", {
    className: "not-found-page__button"
  }, React.createElement(Link, {
    to: BASE_URL
  }, React.createElement(Button, {
    testingContext: "404pageGotoHomeButton",
    secondary: true
  }, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.3090678105",
    defaultMessage: "Zur\xFCck zum Anfang"
  })))));
};

export default NotFound;