import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Review order view showed in checkout.
 */
var CheckoutReview = function CheckoutReview(_ref) {
  var shippingAddress = _ref.shippingAddress,
      billingAddress = _ref.billingAddress,
      shippingMethodName = _ref.shippingMethodName,
      paymentMethodName = _ref.paymentMethodName,
      email = _ref.email,
      errors = _ref.errors;
  return React.createElement(S.Wrapper, {
    "data-test": "sectionTitle"
  }, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.reviewOrder)), React.createElement(S.Grid, null, React.createElement("section", {
    "data-test": "shippingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: shippingAddress,
    email: email
  })), React.createElement("section", {
    "data-test": "billingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.3313352012",
    defaultMessage: "Rechnungsadresse"
  })), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: billingAddress,
    email: email
  })), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.1294713291",
    defaultMessage: "Versandart"
  })), React.createElement(S.Divider, null), React.createElement(S.TextSummary, {
    "data-test": "shippingMethodName"
  }, shippingMethodName)), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.2005458804",
    defaultMessage: "Zahlungsweise"
  })), React.createElement(S.Divider, null), React.createElement(S.TextSummary, {
    "data-test": "paymentMethodName"
  }, paymentMethodName))), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};

export { CheckoutReview };