import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Thank you page after completing the checkout.
 */
var ThankYou = function ThankYou(_ref) {
  var orderNumber = _ref.orderNumber,
      continueShopping = _ref.continueShopping,
      orderDetails = _ref.orderDetails;
  return React.createElement(Container, {
    "data-test": "thankYouView"
  }, React.createElement(S.Wrapper, null, React.createElement(S.ThankYouHeader, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.2151355137",
    defaultMessage: "Danke"
  }), React.createElement("br", null), React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.1096801149",
    defaultMessage: "f\xFCr Ihre Bestellung!"
  }))), React.createElement(S.Paragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.2194457410",
    defaultMessage: "Ihre Bestellnummer lautet"
  }), " ", React.createElement("span", null, orderNumber)), React.createElement(S.Paragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.3363945914",
    defaultMessage: "Wir haben Ihnen eine Auftragsbest\xE4tigung per E-Mail geschickt und werden Sie benachrichtigen, wenn die Bestellung bereit ist."
  })), React.createElement(S.Buttons, null, React.createElement(Button, {
    testingContext: "continueShoppingButton",
    onClick: continueShopping,
    color: "secondary",
    fullWidth: true
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping)), React.createElement(Button, {
    testingContext: "gotoOrderDetailsButton",
    onClick: orderDetails,
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.1920481141",
    defaultMessage: "BESTELLDATEN"
  })))));
};

export { ThankYou };