import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  search: {
    "id": "intl.commonMessages.search",
    defaultMessage: "Suche"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    defaultMessage: "Nicht an Lager"
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    defaultMessage: "Niedriger Lagerbestand"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    defaultMessage: "Keine Artikel vorhanden"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Nicht zum Kauf erhältlich"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "Wird zum Kauf verf\xFCgbar sein am am {date} um {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    defaultMessage: "Das könnte Sie interessieren"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    defaultMessage: "Bitte wählen Sie eine Zahlungsmethode."
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    defaultMessage: "Bitte E-Mail-Adresse angeben."
  },
  account: {
    "id": "intl.commonMessages.account",
    defaultMessage: "Konto"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    defaultMessage: "Mein Konto"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    defaultMessage: "Bestellhistorie"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    defaultMessage: "Adressbuch"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    defaultMessage: "Abmelden"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    defaultMessage: "Vorname"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    defaultMessage: "Nachname"
  },
  password: {
    "id": "intl.commonMessages.password",
    defaultMessage: "Passwort"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    defaultMessage: "Anzahl"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    defaultMessage: "SKU"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    defaultMessage: "Maximale Anzahl ist {maxQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    defaultMessage: "Anzahl"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    defaultMessage: "Subtotal"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    defaultMessage: "Versand"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    defaultMessage: "Gutscheincode"
  },
  total: {
    "id": "intl.commonMessages.total",
    defaultMessage: "Total"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    defaultMessage: "Gesamtbetrag"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    defaultMessage: "Zur Kasse"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    defaultMessage: "E-Mail-Adresse"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    defaultMessage: "E-Mail"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    defaultMessage: "Laden"
  },
  products: {
    "id": "intl.commonMessages.products",
    defaultMessage: "Produkte"
  },
  price: {
    "id": "intl.commonMessages.price",
    defaultMessage: "Preis"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    defaultMessage: "Ausführung"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    defaultMessage: "Telefon"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    defaultMessage: "Telefonnummer: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    defaultMessage: "E-Mail: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    defaultMessage: "Speichern"
  },
  add: {
    "id": "intl.commonMessages.add",
    defaultMessage: "Hinzufügen"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    defaultMessage: "FILTER"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    defaultMessage: "FILTER LÖSCHEN"
  },
  status: {
    "id": "intl.commonMessages.status",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    defaultMessage: "Abbrechen"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Startseite"
  }
});
export var checkoutMessages = defineMessages({
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    defaultMessage: "Adresse"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    defaultMessage: "Versand"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    defaultMessage: "Zahlung"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    defaultMessage: "Übersicht"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    defaultMessage: "Weiter zu Versand"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    defaultMessage: "Weiter zu Zahlung"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    defaultMessage: "Weiter zu Übersicht"
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    defaultMessage: "Bestellung aufgeben"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    defaultMessage: "Neue Adresse hinzufügen"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    defaultMessage: "VERSANDART"
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    defaultMessage: "RECHNUNGSADRESSE"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    defaultMessage: "ZAHLUNGSWEISE"
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    defaultMessage: "BESTELLUNG ÜBERPRÜFEN"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    defaultMessage: "Lieferadresse"
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    defaultMessage: "WEITER EINKAUFEN"
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Löschen..."
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    defaultMessage: "Preis hoch-tief"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    defaultMessage: "Preis tief-hoch"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    defaultMessage: "Name aufsteigend"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    defaultMessage: "Name absteigend"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    defaultMessage: "Letzte Aktualisierung aufsteigend"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Letzte Aktualisierung absteigend"
  }
});
export var paymentStatusMessages = defineMessages({
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    defaultMessage: "Nicht belastet"
  },
  partiallyCharged: {
    "id": "intl.paymentStatusMessages.partiallyCharged",
    defaultMessage: "Teilweise belastet"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    defaultMessage: "Vollständig belastet"
  },
  partiallyRefunded: {
    "id": "intl.paymentStatusMessages.partiallyRefunded",
    defaultMessage: "Teilweise rückerstattet"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    defaultMessage: "Vollständig rückerstattet"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    defaultMessage: "Entwurf"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    defaultMessage: "Nicht erfüllt"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    defaultMessage: "Teilweise erfüllt"
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    defaultMessage: "Erfüllt"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    defaultMessage: "Abgebrochen"
  }
});
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);

    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);

    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);

    default:
      return status;
  }
}