import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 10.5rem;\n  margin: 1rem auto;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", " !important;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n\n  h3 {\n    font-weight: ", ";\n    text-transform: none;\n  }\n\n  p {\n    color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.lightFont;
});
export var GeneralError = styled.p(_templateObject2(), function (props) {
  return props.theme.colors.error;
});
export var InputFields = styled.div(_templateObject3());