import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import "./scss/index.scss";
import * as React from "react";
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { accountConfirmUrl } from "../../../app/routes";
import { Button, Form, TextField } from "../..";
import { maybe } from "../../../core/utils";
import { TypedAccountRegisterMutation } from "./queries";

var showSuccessNotification = function showSuccessNotification(data, hide, alert, intl) {
  var successful = maybe(function () {
    return !data.accountRegister.errors.length;
  });

  if (successful) {
    hide();
    alert.show({
      title: data.accountRegister.requiresConfirmation ? intl.formatMessage({
        defaultMessage: "Bitte überprüfen Sie Ihr E-Mail-Postfach für weitere Anweisungen.",
        "id": "components.OverlayManager.Login.RegisterForm.2638799233"
      }) : intl.formatMessage({
        defaultMessage: "New user has been created",
        "id": "components.OverlayManager.Login.RegisterForm.590842025"
      })
    }, {
      type: "success",
      timeout: 5000
    });
  }
};

var RegisterForm = function RegisterForm(_ref) {
  var hide = _ref.hide;
  var alert = useAlert();
  var intl = useIntl();
  return React.createElement(TypedAccountRegisterMutation, {
    onCompleted: function onCompleted(data) {
      return showSuccessNotification(data, hide, alert, intl);
    }
  }, function (registerCustomer, _ref2) {
    var loading = _ref2.loading,
        data = _ref2.data;
    return React.createElement(Form, {
      errors: maybe(function () {
        return data.accountRegister.errors;
      }, []),
      onSubmit: function onSubmit(event, _ref3) {
        var email = _ref3.email,
            password = _ref3.password;
        event.preventDefault();
        var redirectUrl = "".concat(window.location.origin).concat(accountConfirmUrl);
        registerCustomer({
          variables: {
            email: email,
            password: password,
            redirectUrl: redirectUrl
          }
        });
      }
    }, React.createElement(TextField, {
      name: "email",
      autoComplete: "email",
      label: intl.formatMessage(commonMessages.eMail),
      type: "email",
      required: true
    }), React.createElement(TextField, {
      name: "password",
      autoComplete: "password",
      label: intl.formatMessage(commonMessages.password),
      type: "password",
      required: true
    }), React.createElement("div", {
      className: "login__content__button"
    }, React.createElement(Button, _extends({
      testingContext: "submitRegisterFormButton",
      type: "submit"
    }, loading && {
      disabled: true
    }), loading ? intl.formatMessage(commonMessages.loading) : intl.formatMessage(_defineProperty({
      defaultMessage: "Anmelden",
      "id": "components.OverlayManager.Login.RegisterForm.1799254692"
    }, "id", "components.OverlayManager.Login.RegisterForm.1799254692")))));
  });
};

export default RegisterForm;