import { useOrdersByUser } from "@saleor/sdk/";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { OrderTabel } from "@components/molecules";
import * as S from "./styles";
var ORDERS_PER_APICALL = 5;
export var OrdersHistory = function OrdersHistory(_ref) {
  var history = _ref.history;

  var _useOrdersByUser = useOrdersByUser({
    perPage: ORDERS_PER_APICALL
  }, {
    fetchPolicy: "network-only"
  }),
      data = _useOrdersByUser.data,
      loading = _useOrdersByUser.loading,
      loadMore = _useOrdersByUser.loadMore;

  return loading && !data ? React.createElement(Loader, null) : React.createElement(React.Fragment, null, React.createElement(OrderTabel, {
    orders: data === null || data === void 0 ? void 0 : data.edges,
    history: history
  }), (data === null || data === void 0 ? void 0 : data.pageInfo.hasNextPage) && React.createElement(S.Wrapper, null, React.createElement(Button, {
    testingContext: "loadMoreOrdersButton",
    onClick: function onClick() {
      loadMore({
        after: data.pageInfo.endCursor,
        perPage: ORDERS_PER_APICALL
      });
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersHistory.OrdersHistory.1935191572",
    defaultMessage: "Mehr laden"
  }))));
};