import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-media";
import { commonMessages } from "@temp/intl";
import CostRow from "./CostRow";
import ProductRow from "./ProductRow";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

var Table = function Table(_ref) {
  var subtotal = _ref.subtotal,
      deliveryCost = _ref.deliveryCost,
      totalCost = _ref.totalCost,
      discount = _ref.discount,
      discountName = _ref.discountName,
      lines = _ref.lines,
      rowProps = _objectWithoutProperties(_ref, ["subtotal", "deliveryCost", "totalCost", "discount", "discountName", "lines"]);

  var intl = useIntl();
  return React.createElement(Media, {
    query: {
      minWidth: smallScreen
    }
  }, function (mediumScreen) {
    return React.createElement("table", {
      className: "cart-table"
    }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, React.createElement(FormattedMessage, commonMessages.products)), mediumScreen && React.createElement("th", null, React.createElement(FormattedMessage, commonMessages.price)), React.createElement("th", null, React.createElement(FormattedMessage, commonMessages.variant)), React.createElement("th", {
      className: "cart-table__quantity-header"
    }, React.createElement(FormattedMessage, commonMessages.qty)), React.createElement("th", {
      colSpan: 2
    }, mediumScreen ? React.createElement(FormattedMessage, commonMessages.totalPrice) : React.createElement(FormattedMessage, commonMessages.price)))), React.createElement("tbody", null, lines.map(function (line) {
      return React.createElement(ProductRow, _extends({
        key: line.id,
        line: line,
        mediumScreen: mediumScreen
      }, rowProps));
    })), React.createElement("tfoot", null, React.createElement(CostRow, {
      mediumScreen: mediumScreen,
      heading: intl.formatMessage(commonMessages.subtotal),
      cost: subtotal
    }), discount && React.createElement(CostRow, {
      mediumScreen: mediumScreen,
      heading: intl.formatMessage(_defineProperty({
        defaultMessage: "Rabatt: {discountName}",
        "id": "components.CartTable.Table.3911100212"
      }, "id", "components.CartTable.Table.3911100212"), {
        discountName: discountName
      }),
      cost: discount
    }), deliveryCost && React.createElement(CostRow, {
      mediumScreen: mediumScreen,
      heading: intl.formatMessage(_defineProperty({
        defaultMessage: "Lieferkosten",
        "id": "components.CartTable.Table.3371024507"
      }, "id", "components.CartTable.Table.3371024507")),
      cost: deliveryCost
    }), totalCost && React.createElement(CostRow, {
      mediumScreen: mediumScreen,
      heading: intl.formatMessage(_defineProperty({
        defaultMessage: "Gesamtbetrag",
        "id": "components.CartTable.Table.3957936529"
      }, "id", "components.CartTable.Table.3957936529")),
      cost: totalCost
    })));
  });
};

export default Table;