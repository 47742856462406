import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../..";

var Empty = function Empty(_ref) {
  var overlayHide = _ref.overlayHide;
  return React.createElement("div", {
    className: "cart__empty"
  }, React.createElement("h4", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.1423629762",
    defaultMessage: "Ihr Warenkorb ist leer"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.2225499369",
    defaultMessage: "Sie haben nichts in Ihren Warenkorb gelegt. Wir sind sicher, dass Sie etwas in unserem Shop finden werden."
  })), React.createElement("div", {
    className: "cart__empty__action"
  }, React.createElement(Button, {
    testingContext: "emptyCartHideOverlayButton",
    secondary: true,
    onClick: overlayHide
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.1918453738",
    defaultMessage: "Weiter einkaufen"
  }))));
};

export default Empty;