import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, OverlayTheme, OverlayType } from "..";

var CheckoutAsGuest = function CheckoutAsGuest(_ref) {
  var overlay = _ref.overlay,
      checkoutUrl = _ref.checkoutUrl;
  return React.createElement("div", {
    className: "checkout-login__guest"
  }, React.createElement("h3", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.96456284",
    defaultMessage: "Weiter als Gast"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.554935115",
    defaultMessage: "Wenn Sie kein Konto anlegen m\xF6chten, ist das kein Problem. Sie k\xF6nnen als Gast einchecken. Wir k\xFCmmern uns um Sie genauso wie um registrierte Benutzer/-innen."
  })), React.createElement(Link, {
    to: checkoutUrl
  }, React.createElement(Button, {
    testingContext: "continueAsGuestButton"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.96456284",
    defaultMessage: "Weiter als Gast"
  }))), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.422675366",
    defaultMessage: "oder Sie k\xF6nnen"
  }), " ", React.createElement("span", {
    "data-test": "showRegisterOverlay",
    className: "u-link",
    onClick: function onClick() {
      return overlay.show(OverlayType.register, OverlayTheme.right);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.2048170569",
    defaultMessage: "ein Konto anlegen"
  }))));
};

export default CheckoutAsGuest;